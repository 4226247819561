export class Tenant {
  tenant_id: number;
  domain: string;
  title: string;
  logo_name: string;
  footer: string;
  first_name: string;
  last_name: string;
  company: string;
  phone: number;
  email: string;
  address: string;
  country_id: number;
  timezone_id: number;
  active: number;
  daily_limit: number = 5000;
  monthly_limit: number = 5000;
  mfa_enabled: boolean;
  permissions: any;
  sendfax;string;
  total_inbound:any;
  roles:string;
  daily_sent:number;

}