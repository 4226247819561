import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BrandingService } from '../../../pages/branding/branding.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { AUserService } from '../../../pages/user/user.service';
import { Branding } from '../../../pages/branding/branding';
import { User } from '../../../pages/user/user';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user_id = 1;
  branding: Branding = new Branding;
  user: User = new User; 


  private subscription: Subscription //import from rxjs 

  domain_title: string = 'Snapcom Fax'; 

  auser: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: this.translate.instant('general.change_pass'), link: '/pages/Changepass' }, { title: this.translate.instant('general.log_out'), link: 'auth/logout' } ];

  image_url = "../../../../assets/images/snapcom-logo.png";

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private user_service: AUserService ,
              private breakpointService: NbMediaBreakpointsService, private authService: NbAuthService,
              private router: Router,private nbMenuService: NbMenuService,
              private branding_service: BrandingService, public translate: TranslateService, private app_service: AppService) {

                this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.auser = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
          localStorage.setItem('username', this.auser.username);
          localStorage.setItem('is_admin', this.auser.is_admin);
          localStorage.setItem('is_tenant', this.auser.is_tenant);
          localStorage.setItem('aid', this.auser.user_id);
          localStorage.setItem('tid', this.auser.tenant_id);
          localStorage.setItem('permission', "");
          localStorage.setItem('MFApermission', this.auser.mfa_enabled);  // tenant MFA permission
          if (this.auser.user_permission) localStorage.setItem('permission', this.auser.user_permission);
          this.get_domain_title(this.auser.tenant_id);
          // const brand:any = this.get_domain_title(this.auser.tenant_id);
          // if (!brand) this.get_domain_title(0);
          this.image_url = `${this.app_service.apiUrlBranding}/${this.auser.tenant_id}/media`; 
        }

      });
  }

  ngOnInit() {
     const savedTheme = localStorage.getItem('theme');
     if (savedTheme) {
       this.themeService.changeTheme(savedTheme);
      }

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      this.subscription = this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == 'Log out') {
        let copy_token = localStorage.getItem('copy_token');
        if (copy_token != null) {
          localStorage.removeItem('copy_token');
          window.location.reload();
    
          this.authService.getToken()
          .subscribe((token: NbAuthJWTToken,
          ) => {
            if (token && token.getValue()) {
              let auser = token.getPayload();
              localStorage.setItem('username', auser.username);
              localStorage.setItem('is_admin', auser.is_admin);
            }
          });
    
          this.router.navigate(['/dashboard']);
    
        }
        else {
          if(localStorage.getItem('MFApermission') == '1') {
            // after logout update user table
            this.auser.verify = 0;
            this.user_service.update_User(this.auser).then(data => {
            });
          }
          this.router.navigate(['auth/logout']);
        }
      }
    });
      
  
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }

  changeTheme(themeName: string) {
  localStorage.setItem('theme', themeName);
  this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  ngDoCheck() {
    let myname = localStorage.getItem('username');
    if (myname != undefined ) {
      this.auser.username = myname;
    }
  }  
    
  get_domain_title(tenant_id) { 
    this.branding_service.get_Branding(tenant_id).then(response => {
      document.title = response.title;
      this.domain_title = response.title;
    })
    .catch(err => {
      console.log(err);
      this.search_branding();
    })
  }
  search_branding() {
    this.user_service.get_UserData(this.user_id).then(response => {
      this.user.tenant_id = response['tenant_id'];
      this.get_branding();
    });
  }

  get_branding() {
    this.branding_service.get_Branding(this.user.tenant_id).then(response => {
        this.branding.defaultvalue = response.defaultvalue;
        if (this.branding.defaultvalue < 1) {
          console.log(this.branding.defaultvalue);
        } else {
          this.get_domain_title(this.user.tenant_id);
          this.image_url = `${this.app_service.apiUrlBranding}/${this.user.tenant_id}/media`;
        }
      });
    }

}
